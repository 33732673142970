import * as React from "react";
import Header from "../components/header";
import "@fontsource/roboto/900-italic.css";
import "@fontsource/roboto/400.css";
import birds from "../images/birds.png";
import birdsCircle from "../images/birds_circle.png";
import "../styles/index.scss";
import Footer from "../components/footer";
import Seo from "../components/helmet";

const IndexPage = () => {
  return (
    <React.Fragment>
      <Seo title="Home - Flock Finance"></Seo>
      <Header></Header>

      <title>Home Page</title>
      <div className="hero">
        <img alt="Flock Birds" src={birds} />
        <h1>WELCOME TO THE FUTURE OF FINANCE</h1>
        <p>
          Until now, readily available finance has been controlled and
          restricted by financial institutions. Similarly, many investment
          products with stable and healthy returns are kept behind those same
          closed doors.
          <br />
          <br />
          Flock is levelling the playing field. We’re starting by making the
          buying and selling of invoices an open and accessible marketplace for
          individual investors and businesses of all sizes. This means more
          options, better rates and more predictable returns.
          <br />
          <br />
          We are currently in a closed BETA test but if you’d like to find out
          more then click on one of the registration options below.
        </p>
      </div>
      <div className="blocks">
        <div className="clear">
          <div className="content">
            <h2>SELLERS</h2>
            <p>
              We know how hard it can be for small business owners to keep on
              top of their finances, and one of the biggest pain points for all
              businesses is stabilising cash flow.
              <br />
              <br />
              Accessing invoice finance has previously been difficult and
              expensive but Flock has removed many of these restrictions and is
              making invoice finance available to as many businesses as
              possible.
              <br />
              <br />
              Flock is an online marketplace that connects business owners like
              you (Sellers), with retail investors - Buyers. If you want to find
              out more about how Flock can help with your cash flow, register
              your interest now.
            </p>
            <a href="/register-seller">REGISTER</a>
          </div>
        </div>
        <div className="dark">
          <div className="content">
            <h2>BUYERS</h2>
            <p>
              We know how difficult it is to make your hard earned savings work
              for you in turbulent economic times. If you’re looking to add a
              more stable and predictable investment option to your portfolio
              then you should consider invoice finance. Flock is an online
              marketplace that connects investors like you (Buyers), with
              business owners - Sellers.
              <br />
              <br />
              Explore sellers and their outstanding invoices and fully or
              partially finance their receivables in the form of a loan, making
              a return on your loans once the invoices are paid off.
              <br />
              <br />
              If you’re an individual investor and want to find out more about
              Flock then register your interest now.
            </p>
            <a href="/register">REGISTER</a>
          </div>
        </div>
      </div>
      <div className="about">
        <div className="content">
          <h2>About Flock</h2>
          <p>
            Our mission is to hand over the choice and power of raising finance
            and making investments to you, the people. We offer Australian
            businesses and residents decentralised finance and investment
            options in the only way this can truly be achieved - by creating a
            peer-to-peer marketplace where buyers and sellers can agree terms
            directly with one another.
            <br />
            <br />
            We are currently in a closed BETA test with our first decentralised
            finance product - invoice finance - but if you’d like to find out
            more then click on one of the registration options above.
          </p>
        </div>
        <div className="image">
          <img alt="Flock Birds Circle" src={birdsCircle} />
        </div>
      </div>
      <Footer></Footer>
    </React.Fragment>
  );
};

export default IndexPage;
